<template>
    <div class="full-height fl-y">
        <top-bar title="Pragati" description="Interview Reports">
            <div class="w-25r mr-3">
                <btn v-if="interviewId === 0" color="success" text="Start the Interview" @click="startInterview"/>
            </div>
        </top-bar>

        <div style="max-height: 100%; margin-bottom: 250px;" class="container">
            <div class="fl-gr-1 p-2 fl-y" style="overflow: scroll;" ref="messageContainer">
                <chat-bubble
                    :key="index"
                    v-for="( message, index) in messages"
                    :role="message.role"
                    :caption="message.caption"
                    :message="message.message"
                />

                <div v-if="loading" class="b-1 p-3 round-2">
                    <loading-animation /> Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import TopBar from '@components/TopBar.vue';
import ChatBubble from '@components/ChatBubble.vue';
import urls from '../../data/urls';
import markdown from '@/utils/Markdown';

export default {
    name: 'Interview',
    components: {
        ChatBubble,
        TopBar
    },
    data () {
        return {
            loading: false,
            interviewId: 0,
            answer: '',
            messages: []
        };
    },

    methods: {
        async startInterview () {
            try {
                const id = prompt('Enter the interview id');
                this.interviewId = id;
                const data = { interview_id: id };
                const response = await axios.form(urls.interview.report, data);
                console.log(response);
                console.log(response.data);

                const questions = response.data.questions;
                for (let i = 0; i < questions.length; i++) {
                    const question = questions[i];
                    this.messages.push({
                        caption: 'Question',
                        role: 'question',
                        message: question.question
                    });

                    const answered = question.answered;
                    if (answered) {
                        this.messages.push({
                            role: 'answer',
                            message: question.given_answer
                        });

                        this.messages.push({
                            role: 'comment',
                            message: question.comment
                        });

                        this.messages.push({
                            role: 'correct-answer',
                            message: markdown(question.correct_answer)
                        });

                        this.messages.push({
                            role: 'explanation',
                            message: markdown(question.explanation)
                        });
                    }
                }
            } catch (error) {
                console.error(error);
            }
            this.doScroll();
        },

        doScroll () {
            const messageContainer = this.$refs.messageContainer;
            setTimeout(() => {
                const lastChild = messageContainer.lastElementChild;
                if (lastChild) {
                    lastChild.scrollIntoView({ behavior: 'smooth' });
                }
            }, 200);
        }

    }
};
</script>
